import React, {useMemo, useEffect, useRef, useState} from 'react'
import {FormStyle, FormHeader, FormTitle, LinkUnderline, Bg, FormTermsHolder, FormSocialViewBack} from './style'
import {useForm} from 'react-hook-form'
import Input from '../../../../components/Form/Input'
import {ButtonFull} from '../../../../components/Form/Button'
import Checkbox from '../../../../components/Form/Checkbox'
import * as yup from 'yup'
import {useYupValidationResolver} from '../../../../utils/common'
import {FormattedMessage, useIntl} from 'react-intl'
import {showPopup} from "../../../../components/Modal/slices";
import {useDispatch, useSelector} from "react-redux";
import {merchantThemes} from "../../../../components/Variable";
import {getEmail} from "../../thunk";
import {Icon} from "../../../../components/Icon";
import {toggleSocialView} from "../../slices";
import {showLoader} from "../../../../components/Loader/slices";

const Form = ({
              openWidget = () => {
              },
              orderId = '',
              email = '',
              onClickStatus = () => {},
              currentTheme = {},
              returnadoToken = null,
              isSocialView = null
          }) => {
    const intl = useIntl()
    let [formChange, setFromChange] = useState(0)
    const dispatch = useDispatch()

    //const {isVisible} = useSelector((state) => state.popup);
    const {terms, policy} = useSelector((state) => state.main);
    const {locale, shopId} = useSelector((state) => state.root);
    const loginForm = useRef();

    const validationSchema = useMemo(
        () =>
            yup.object().shape({
                email: yup
                    .string()
                    .email(intl.formatMessage({
                        id: 'reclamation.contact.us.dialog.input.email',
                        defaultMessage: 'Enter your email',
                    }))
                    .required("")
                    .trim(),
                orderId: yup.string().trim(),
                termAndCond: yup
                    .boolean()
                    .oneOf([true], intl.formatMessage({
                        id: 'standalone.login.form.checkbox.required',
                        defaultMessage: 'You need to accept the terms before being able to continue',
                    })),
            }),
        [intl]
    )


    const openPolicyPopupWindow = (e) => {
        e.preventDefault();
        dispatch(showPopup({
            type: 'policy',
            data: policy
        }))
    }
    const openTermsPopupWindow = (e) => {
        e.preventDefault();
        dispatch(showPopup({
            type: 'terms',
            data: terms
        }))
    }

    const {
        register,
        handleSubmit,
        errors,
        formState: {},  // eslint-disable-line no-empty-pattern
        clearErrors
    } = useForm({
        resolver: useYupValidationResolver(validationSchema),
        defaultValues: {
            email: email || '', //mmallardsmith@me.com
            orderId: orderId || '', //3685177327701
        },
    })

    useEffect(() => {
        clearErrors()
    }, [locale, clearErrors])

    const onSubmit = (data) => {
        let {email, orderId, termAndCond} = data
        if (email && orderId && termAndCond) {
            data.orderId = orderId.replace(/\#/g, '%23');
            if(!data.language) {
                data.language = locale
            }
            openWidget(data)
        }
        if (email && termAndCond && !orderId) {
            dispatch(getEmail({
                email: email.replace(/\+/g, '%2B'),
                language: locale,
                shopId: shopId
            }))
        }
        dispatch(showLoader())
    }

    const onTokenLogin = () => {
        openWidget({
            returnadoToken: returnadoToken
        })
    }

    const isEmailValue = () => {
        let {current} = loginForm;

        if(current) {
            let email = current ? current.querySelector('#email-input') : {};
            return email.value;
        }

        return false

    }

    const isOrderIdValue = () => {
        let {current} = loginForm;
        if(current) {
            let orderId = current ? current.querySelector('#order-id-input') : {};
            return orderId.value;
        }
        return false;
    }

    const isNeedToShowEmailLinkTitle = () => {
        return isEmailValue() && !isOrderIdValue();
    }

    return (
        <Bg>
            <FormStyle onSubmit={handleSubmit(onSubmit)} onChange={()=>{setFromChange(formChange+=1)}} noValidate ref={loginForm} locale={locale}>
                <FormHeader>
                    {
                        isSocialView === false || isSocialView === true
                            ?
                            <FormSocialViewBack onClick={()=>{
                                dispatch(toggleSocialView())
                            }}>
                                <Icon component={'arrowDown'} />
                            </FormSocialViewBack>
                            :
                            ''
                    }

                    <FormTitle>
                        {
                            currentTheme && currentTheme.name === merchantThemes.woodwood
                                ?
                                <FormattedMessage
                                    id='login.intro.not.satisfied.header'
                                    defaultMessage='Not satisfied with your purchase?'
                                />
                                :
                                <FormattedMessage
                                    id='login.intro.header'
                                    defaultMessage='Login with email'
                                />
                        }
                    </FormTitle>
                </FormHeader>
                <Input
                    id='email-input'
                    type='email'
                    name='email'
                    placeholder={intl.formatMessage({
                        id: 'login.form.email.placeholder',
                        defaultMessage: 'email',
                    })}
                    register={register}
                    isCustomized={true}
                    error={errors.email}
                    className='mb-3'
                />
                <Input
                    id='order-id-input'
                    type='text'
                    name='orderId'
                    placeholder={intl.formatMessage({
                        id: 'login.form.order.placeholder',
                        defaultMessage: 'order number',
                    })}
                    register={register}
                    isCustomized={true}
                    error={errors.orderId}
                    className='mb-4'
                />
                {
                    !isEmailValue() && !isOrderIdValue() && returnadoToken
                        ?
                        <ButtonFull
                            w100
                            type='button'
                            className='mb-4'
                            iconPosition={'left'}
                            onClick={onTokenLogin}
                        >
                            <FormattedMessage
                                id='login.form.login.token.button.text'
                                defaultMessage='VIEW ORDER BY TOKEN'
                            />
                        </ButtonFull>
                        :
                        <button type="submit" className='main-form-submit-button'>
                            <FormattedMessage
                                id={`${isNeedToShowEmailLinkTitle() ? 'login.form.login.button.onlyEmail' : 'login.form.login.button.text'}`}
                                defaultMessage={`${isNeedToShowEmailLinkTitle() ? 'E-MAIL ME LINK' : 'VIEW ORDER'}`}
                            />
                        </button>
                }

                <FormTermsHolder>
                    <Checkbox
                        register={register}
                        name='termAndCond'
                        error={errors.termAndCond}
                    >
                        <FormattedMessage
                            id='login.form.termsAndConditions'
                            defaultMessage="I accept Returnado's"
                        />{' '}
                        {!!(terms && currentTheme && currentTheme.name !== merchantThemes.marysia) && (
                            <LinkUnderline onClick={openTermsPopupWindow}>
                                <FormattedMessage
                                    id='login.form.termsAndConditions.link'
                                    defaultMessage='Terms and Conditions'
                                />
                            </LinkUnderline>)}{' '}
                        {(!!(currentTheme && currentTheme.name !== merchantThemes.marysia) && <FormattedMessage
                            id='login.form.termsAndConditions.label'
                            defaultMessage='and'
                        />)}{' '}
                        {!!policy && (<LinkUnderline onClick={openPolicyPopupWindow}>
                            <FormattedMessage
                                id='login.form.privacyPolicy.link'
                                defaultMessage='Privacy Policy'
                            />
                        </LinkUnderline>)}
                    </Checkbox>
                </FormTermsHolder>
            </FormStyle>
        </Bg>
    )
}
export default Form
