import React, {Component} from 'react'
import {connect} from 'react-redux'
import {FormattedMessage, injectIntl} from 'react-intl'
import {bindActionCreators, compose} from 'redux'
import {
    MainContent,
    MainContentLanguageWrapper,
    BoxAnimation,
    MainText,
    Title,
    AccordionWrapper,
    ArrowControlWrapper
} from "./style";
import MainForm from "./components/MainForm";
import Footer from "./components/Footer";
import PerfectScrollbar from 'react-perfect-scrollbar'
import Accordions from "../../components/Accordions";
import MerchantLogo from "../../components/MerchantLogo";
import CookiesMessage from "../../components/CookiesMessage";
import {getCurrentTheme, getUrlParameter} from "../../utils/common";
import {Icon} from "../../components/Icon";
import {getShopCodes} from "./thunk";
import {merchantThemes} from "../../components/Variable";
import LoggedInView from "./components/LoggedInView";
import {setLoggedIn, setLoggedOut} from "./slices";
import SocialNetworksView from "./components/SocialNetworksView";
import {PopupWindow} from "../../components/Modal";
import {hidePopup} from "../../components/Modal/slices";
import MultiMarketComboBox from "../../components/MultiMarketComboBox";
import LanguageSwitcher from "../../components/LanguageProvider/LanguageSwitcher";
import {LoaderItem} from "../../components/Loader/style"

const sourceSnippet = process.env.REACT_APP_SOURCE || './snippet.js'

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            help: false,
            wasScriptAdded: false,
        }
        this.wasIspectletInitialized = true
    }

    /**
     * initWidget
     * @description - widget initialization
     *
     * */
    initWidget = (codes) => {
        this.insertReturnadoScript(codes);
    };

    /**
     * getCurrentCodeKey
     * @description - get current merchantShopCode
     *
     * */
    getCurrentCodeKey = (codes) => {
        let currentMerchantCode = null;
        let {merchantShopCode} = this.props;
        for (let key in codes) {
            if (codes.hasOwnProperty(key) && !currentMerchantCode) {
                currentMerchantCode = key;
            }
        }
        return currentMerchantCode || merchantShopCode;
    }

    /**
     * insertReturnadoScript
     * @insertReturnadoScript - add script fo loading main widget functionality
     *
     * */
    insertReturnadoScript = (codes) => {
        let {locale = 'en', countryId} = this.props;
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = sourceSnippet;
        document.getElementsByTagName('head')[0].appendChild(script);
        let currentMerchantCode = this.getCurrentCodeKey(codes)
        this.setState({
            wasScriptAdded: true
        }, () => {
            setTimeout(() => {
                console.log('widget root init')
                if (currentMerchantCode) {
                    let initWidgetData = {
                        merchantShopCode: currentMerchantCode,
                        language: locale ? locale : 'en',
                        countryId: countryId
                    }
                    window?.rWidget?.init(initWidgetData)
                }
            }, 1500)
        })
    };

    /**
     * openWidget {Function}
     * @description - open Widget.
     *
     * */
    openWidget = (openData) => {
        let {locale} = this.props;
        let {email = null, orderId = null} = openData;
        let {codes} = this.props;
        let loginData = {
            shopId: this.getCurrentCodeKey(codes)
        }

        if (orderId) {
            loginData.orderId = orderId;
        }
        if (email) {
            loginData.email = email.replace(/\+/g, '%2B');
        }
        if(locale) {
            loginData.language = locale;
        }

        window?.rWidget?.openWidget(loginData, orderId)
    };

    /**
     * setStatusOption {Function}
     * @description - set true ir false option status
     *
     * */
    setStatusOption = () => {
        const help = this.state.help
        this.setState({
            help: !help
        })
        if (help) this._scrollRef.scrollTop = 0;
    };

    /**
     * setTitle {Function}
     * @description - change title to appropriate text
     *
     * */
    setTitle = (themeName) => {
        let titleStr = 'Return Service - Returnado';
        let theme = '';

        switch (themeName) {
            case merchantThemes.woodwood:
                theme = 'Wood Wood';
                break;
            case merchantThemes.marysia:
                theme = 'Marysia';
                break;
            case merchantThemes.aycane:
                theme = 'Aycane';
                break;
            case merchantThemes.douchebags:
                theme = 'Douchebags';
                break;
            case merchantThemes.royal_design:
                theme = 'Royal Design';
                break;
            case merchantThemes.rum21:
                theme = 'Rum21';
                break;
            case merchantThemes.henrylloyd:
                theme = 'Henry Lloyd';
                break;
            case merchantThemes.hh:
                theme = 'Helly Hansen';
                break;
            case merchantThemes.hh_ww:
                theme = 'Helly Hansen Workwear';
                break;
            case merchantThemes.bymalina:
                theme = 'By Malina';
                break;
            case merchantThemes["8848"]:
                theme = '8848 Altitude';
                break;
            case merchantThemes.bjornborg:
                theme = 'Björn Borg';
                break;
            case merchantThemes.ogoship:
                theme = 'OGOShip';
                break;
            case merchantThemes.toteme:
                theme = 'Toteme';
                break;
            case merchantThemes.asket:
                theme = 'Asket';
                break;
            case merchantThemes.musto:
                theme = 'Musto';
                break;
            case merchantThemes.sns:
                theme = 'SNS';
                break;
            case merchantThemes.indiska:
                theme = 'indiska';
                break;
            case merchantThemes.quiz:
                theme = 'quiz';
                break;
            case merchantThemes.vitvaruexperten:
                theme = 'vitvaruexperten';
                break;
            case merchantThemes.stutterheim:
                theme = 'stutterheim';
                break;
            case merchantThemes.sportshopen:
                theme = 'sportshopen';
                break;
            case merchantThemes.sportshopen_outlet:
                theme = 'sportshopen_outlet';
                break;
            case merchantThemes.swedemount:
                theme = 'swedemount';
                break;
            case merchantThemes.madlady:
                theme = 'madlady';
                break;
            case merchantThemes.arena:
                theme = 'arena';
                break;
            case merchantThemes.malene_birger:
                theme = 'malene_birger';
                break;
            case merchantThemes.galvin_green:
                theme = 'galvin_green';
                break;
            case merchantThemes.karkkainen:
                theme = 'karkkainen';
                break;
            default:
                theme = ''
                break;
        }

        document.title = `${theme} ${titleStr}`
    }

    /**
     * isLocaleLoaded {Function}
     * @description - detect was locale loaded or not.
     *
     * */
    isLocaleLoaded = () => {
        let {messages, locale} = this.props;
        let isLocaleLoaded = messages[locale] && Object.keys(messages[locale]).length
        return isLocaleLoaded;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {codes, currentTheme} = this.props;
        if (prevProps.codes !== codes) {
            if (!document.querySelector('script[src$="snippet.js"]')) {
                this.initWidget(codes);
            }
        }

        if (currentTheme.name && currentTheme.name !== merchantThemes.default) {
            // set an appropriate title string
            this.setTitle(currentTheme.name);
        }

    }


    componentDidMount() {
        let {shopId, currentTheme} = this.props;

        this.props.getShopCodes(shopId, currentTheme.name)

        // inspectlet initialization
        if (!this.wasIspectletInitialized) {
            this.wasIspectletInitialized = true
            console.log('init inspectlet')
            window.__insp = window.__insp || [];
            window.__insp.push(['wid', 495437874]);
            var ldinsp = function () {
                if (typeof window.__inspld != "undefined") return;
                window.__inspld = 1;
                var insp = document.createElement('script');
                insp.type = 'text/javascript';
                insp.async = true;
                insp.id = "inspsync";
                insp.src = ('https:' === document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=495437874&r=' + Math.floor(new Date().getTime() / 3600000);
                var x = document.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(insp, x);
            };
            setTimeout(ldinsp, 0);
        }

        if (!window.returnadoActions) {
            window.returnadoActions = {}
            window.returnadoActions.setStandaloneLoggedIn = this.props.setLoggedIn;
            window.returnadoActions.setStandaloneLoggedOut = this.props.setLoggedOut;
        }
    }


    render() {
        const isOpen = this.state.help;
        let {currentTheme, returnadoToken, isLoggedIn, isVisible, isSocialView, shopProperties} = this.props;
        let currentThemeName = getCurrentTheme();
        let isMarketSwitcherDisabled = shopProperties ? shopProperties['widget.markets.select.disabled'] && shopProperties['widget.markets.select.disabled'] === 'false' ? false : true : true;
        let isTokenLogin = getUrlParameter('returnadoToken');

        return (
            <>
                <PopupWindow
                    open={isVisible}
                    closeOnDocumentClick
                    onClose={() => {
                        this.props.hidePopup();
                    }}
                />
                <PerfectScrollbar containerRef={ref => {
                    this._scrollRef = ref;
                }}>
                    <CookiesMessage/>
                    <MainContent>
                        <MainContentLanguageWrapper>
                            {
                                isMarketSwitcherDisabled || isTokenLogin
                                ?
                                ''
                                :
                                <MultiMarketComboBox />
                            }

                            <LanguageSwitcher isMarketSwitcherDisabled={isMarketSwitcherDisabled} isTokenLogin={isTokenLogin} />
                        </MainContentLanguageWrapper>
                        <MerchantLogo isOpen={false}/>
                        {
                            !this.isLocaleLoaded()
                                ?
                                <LoaderItem className='loader' />
                                :
                                <BoxAnimation>
                                    {
                                        !isLoggedIn
                                            ?
                                            isSocialView
                                                ?
                                                <SocialNetworksView currentTheme={currentTheme}/>
                                                :
                                                <MainForm currentTheme={currentTheme}
                                                          onClickStatus={this.setStatusOption}
                                                          returnadoToken={returnadoToken} openWidget={this.openWidget}
                                                          isSocialView={isSocialView}/>
                                            :
                                            <LoggedInView openWidget={this.openWidget}>
                                                <FormattedMessage
                                                    id='login.form.login.button.allFields'
                                                    defaultMessage='VIEW ORDER'
                                                />
                                            </LoggedInView>
                                    }

                                    {
                                        !isTokenLogin
                                            ?
                                            <MainText isOpen={isOpen} themeName={currentThemeName}>
                                                <Title
                                                    isOpen={isOpen}
                                                    onClick={this.setStatusOption}
                                                >
                                                    <FormattedMessage
                                                        id='login.form.standalone.exchange.policy.title'
                                                        defaultMessage='Return and Exchange policy'
                                                    />
                                                    <ArrowControlWrapper>
                                                        <Icon component={'arrowDown'}/>
                                                    </ArrowControlWrapper>
                                                </Title>
                                                <AccordionWrapper isOpen={isOpen}>
                                                    <Accordions/>
                                                </AccordionWrapper>
                                            </MainText>
                                            :
                                            ''
                                    }

                                </BoxAnimation>
                        }
                    </MainContent>
                </PerfectScrollbar>
                <Footer currentTheme={currentTheme}/>
            </>
        )
    }
}

function mapStateToProps({root, main, popup}) {
    return {
        shopId: root.shopId,
        currentTheme: root.currentTheme,
        shopProperties: root.shopProperties,
        locale: root.locale,
        messages: root.messages,
        codes: main.codes,
        isLoggedIn: main.isLoggedIn,
        isVisible: popup.isVisible,
        isSocialView: main.isSocialView,
        merchantShopCode: root.merchantShopCode,
        countryId: root.countryId,
    }
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            getShopCodes,
            setLoggedIn,
            setLoggedOut,
            hidePopup
        },
        dispatcher
    )
}

const sippingConnect = connect(mapStateToProps, matchDispatchToProps)

export default compose(sippingConnect, injectIntl)(Main)
